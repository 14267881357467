import * as Actions from "../actions";

const initialState = {
  project: [],
  designer: [],
  totalSize: 0,
};

const projectReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_PROJECT: {
      return {
        ...state,
        ...action,
      };
    }
    default: {
      return state;
    }
  }
};

export default projectReducer;
