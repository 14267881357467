import { FuseAnimate, FusePageCarded } from '@fuse';
import { Button, Chip, CircularProgress, Divider, Drawer, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import SendIcon from '@material-ui/icons/Send';
import { Col, Form, Icon, Input, Modal, Row, Select, Spin, Table, Typography } from 'antd';
import { API_KC_URL } from 'app/main/config';
import * as appActions from 'app/store/actions';
import withReducer from 'app/store/withReducer';
import React, { Component } from "react";
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Actions from './store/actions';
import reducer from './store/reducers';

const { Title } = Typography;
const { confirm } = Modal;
const FormItem = Form.Item;
const { Option } = Select;
const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 12 },
};
const defaultPagination = {
    pageSizeOptions: ['10', '20', '50', '70'],
    showSizeChanger: true,
    size: 'small',
    position: 'top',
}
const styles = theme => ({
    button: {
        marginTop: "-12px",
    },
    input: {
        display: 'none',
    },
    list: {
        width: 500,
    }
});

class Project extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user_ids: [],
            sort: false,
            filter: false,
            searchText: '',
        };
        props.getProject();

    }

    toggleDrawer = (type, record, open) => () => {
        if (type === "new") {
            this.setState({
                id: null,
                type: type,
                user_ids: [],
            })
        }
        else if (type === "edit") {
            var splitArr = [];
            if (record.Consult_designers != null) {
                splitArr = record.Consult_designers.split('/');
                splitArr.pop();
                splitArr.reverse().pop();
                splitArr.reverse()
                splitArr = splitArr.map(el => parseInt(el, 10));
            }

            this.setState({
                type: type,
                id: record.id,
                user_ids: splitArr,
            })
        }
        this.setState({
            visible: open,
        });
    };

    handleSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const { type, id } = this.state;
                this.setState({ visible: false });
                const designerEmail = [];
                this.props.designer.map(designer => {
                    if (values.user_ids.includes(designer.id))
                        designerEmail.push(designer.email);
                })
                if (type === "edit" && id !== null) {
                    this.props.updateProject(values, id, designerEmail);
                }
            }
        });
    }

    handleDeleteEvent = (record) => {
        const { deleteProject } = this.props;
        confirm({
            title: 'Do you want to delete this Item?',
            onOk() {
                deleteProject(record)
            },
            onCancel() { },
        });
    }

    getColumnFilterProps = () => ({
        filterDropdown: () => (
            <div style={{ padding: 8 }}>
                <Button
                    onClick={() => { this.props.getProject(this.state.sort, true); this.setState({ filter: true }); }}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Filter true
            </Button>
                <Button onClick={() => { this.props.getProject(this.state.sort, false); this.setState({ filter: false }); }} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
    });
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={'search...'}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
            </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
            </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => {
            if (record[dataIndex] === null) return 0;
            return record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
        },
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[this.state.searchText]}
                autoEscape
                textToHighlight={text === null ? "" : text.toString()}
            />
        ),
    });

    onInputChange = e => {
        this.setState({ searchText: e.target.value })
    }
    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render() {
        const { classes, project, designer, isLoading, user, getProject, downloading } = this.props;
        const { user_ids, visible, type, sort, filter } = this.state;
        const { getFieldDecorator, setFieldsValue } = this.props.form;
        const user_token = window.localStorage.getItem('jwt_access_token');
        const designerItems = [];
        designer.forEach(fElement => {
            designerItems.push(<Option key={fElement.id} value={fElement.id}>{fElement.userid}</Option>);
        })

        const columns = [
            {
                key: 'line_number',
                title: ' No',
                dataIndex: 'lineId',
                width: 60,
            },
            {
                key: 'saved_at',
                title: 'Latest Saved Date',
                dataIndex: 'saved_at',
                width: 200,
                sorter: true,
                ...this.getColumnSearchProps('saved_at')
            },
            {
                key: 'created_at',
                title: 'Inital Created Date',
                dataIndex: 'created_at',
                width: 200,
                sorter: true,
                ...this.getColumnSearchProps('created_at')
            },
            {
                key: 'firstName',
                title: 'First Name',
                dataIndex: 'firstName',
                width: 130,
                ...this.getColumnSearchProps('firstName')
            },
            {
                key: 'lastName',
                title: 'Last Name',
                dataIndex: 'lastName',
                width: 130,
                ...this.getColumnSearchProps('lastName')
            },
            {
                key: 'email',
                title: 'Customer Email',
                dataIndex: 'email',
                width: 200,
                ...this.getColumnSearchProps('email')
            },
            {
                key: 'phone',
                title: 'Phone',
                dataIndex: 'phone',
                width: 150,
                ...this.getColumnSearchProps('phone')
            },
            {
                key: 'project_name',
                title: 'Project Name',
                dataIndex: 'title',
                width: 150,
                ...this.getColumnSearchProps('title')
            },
            {
                key: 'door_selection',
                title: 'Door selection',
                dataIndex: 'door_selection',
                width: 200,
                render: text => {
                    let reg = /[^\/]+\/[^\/]+\/([^\/]+)\/(.+)/; // Category/Style/Color/thumbnail
                    let info = reg.exec(text);
                    if (!info) return null;
                    let name = info[1];
                    let thumbnail = info[2];
                    return (
                        <>
                            <img src={thumbnail} height="30" style={{ marginRight: 5 }} />
                            {name}
                        </>
                    );
                }
            },
            {
                key: 'action',
                title: 'Action',
                dataIndex: 'action',
                width: 250,
                render: (text, record) => {
                    let utm = {
                        utm_source: record.utm_source,
                        utm_medium: record.utm_medium,
                        utm_campaign: record.utm_campaign,
                        utm_term: record.utm_term,
                        utm_content: record.utm_content,
                        gclid: record.gclid,
                        msclkid: record.msclkid,
                        fbclid: record.fbclid,
                        ttclid: record.ttclid,
                    };
                    let urmParam = new URLSearchParams(utm);
                    return (
                        <Row key="sss">
                            {user.role === 'superdesigner' || (user.role === 'superadmin' && record.is_assistance_requested !== "") ? (
                                <Button
                                    className={classes.button}
                                    color="primary"
                                    onClick={this.toggleDrawer("edit", record, true)}
                                    size="small"
                                    style={{ fontSize: 10, marginRight: 5 }}
                                    variant="contained"
                                >
                                    Assign
                                    <EditIcon />
                                </Button>
                            ) : null}
                            <Button
                                className={classes.button}
                                color="secondary"
                                href={`${API_KC_URL}/project/${user.role}/${user_token}/${record.id}?${urmParam.toString()}`}
                                size="small"
                                style={{ fontSize: 10 }}
                                target="_blank"
                                variant="contained"
                            >
                                link to view
                                <SendIcon />
                            </Button>
                        </Row>
                    );
                },
            },
            {
                key : "projected_submitted",
                title : "Submitted",
                dataIndex : "project_submitted",
                width : 200,
                render: (text, record) => (
                    text === "" ? 
                    <Chip
                        label="No"
                        className={classes.button}
                        color="primary"
                    /> : 
                    <Chip
                        label="Yes"
                        className={classes.button}
                        color="primary"
                    />
                )
            },
            {
                key: 'project_submitted_options',
                title: 'Submitted options',
                dataIndex: 'project_submitted',
                width: 200,
                render: (text, record) => (
                    text === "" || text === '0' ? 
                    "" : 
                    <div>
                        {text.split(',').map(it => {
                            let label = "";
                            switch(it){
                                case "1":
                                    label = "General Questions";
                                    break;
                                case "2":
                                    label = "Moldings help";
                                    break;
                                case "3":
                                    label = "Added to cart";
                                    break;
                                case "4":
                                    label = "Cart verification requested";
                                    break;
                                default:
                                    break;
                            }
                            return(
                                <Chip
                                    style={{margin: "5px 0px"}}
                                    label={label}
                                    className={classes.button}
                                    color="secondary"
                                />
                            )
                        })}
                    </div>
                )
            },
            {
                key : "is_assistance_requested",
                title : "Assistance Requested",
                dataIndex : "is_assistance_requested",
                width : 200,
                render: (text, record) => (
                    text === "" ? 
                    <Chip
                        label="No"
                        className={classes.button}
                        color="primary"
                    /> : 
                    <Chip
                        label="Yes"
                        className={classes.button}
                        color="primary"
                    />
                )
            },
            {
                key: 'is_assistance_requested_options',
                title: 'Assistance options',
                dataIndex: 'is_assistance_requested',
                width: 200,
                render: (text, record) => (
                    text === "" || text === '0' ? 
                    " " : 
                    <div>
                        {text.split(',').map(it => {
                            let label = "";
                            switch(it){
                                case "1":
                                    label = "No Measurements";
                                    break;
                                case "2":
                                    label = "Floorplan help";
                                    break;
                                case "3":
                                    label = "Layout help";
                                    break;
                                case "4":
                                    label = "Completing design";
                                    break;
                                default:
                                    break;
                            }
                            return(
                                <Chip
                                    style={{margin: "5px 0px"}}
                                    label={label}
                                    className={classes.button}
                                    color="secondary"
                                />
                            )
                        })}
                    </div>
                ),
                ...this.getColumnFilterProps()
            },
            {
                key: 'consult_designers',
                title: 'Assigned Designer',
                dataIndex: 'Consult_designers_name',
                width: 200,
                render: (text, record) => {
                    var splitArr = [];
                    if (record.Consult_designers != null) {
                        splitArr = record.Consult_designers.split('/');
                        splitArr.pop();
                        splitArr.reverse().pop();
                        splitArr.reverse()
                        splitArr = splitArr.map(el => parseInt(el, 10));
                    }
                    return (<Select
                        mode="multiple"
                        disabled={true}
                        value={splitArr}
                        dropdownStyle={{ zIndex: 10000, maxHeight: 400, overflow: 'auto' }}
                        style={{ width: '100%' }}
                    >
                        {designerItems}
                    </Select>);
                },
            },
            {
                key: 'save_notes',
                title: 'Save Notes',
                dataIndex: 'save_notes',
                width: 200,
            },
            {
                key: 'submit_checked',
                title: "Preferred Contact Method",
                dataIndex: 'submit_checked',
                width: 200,
                render: (text, record) => (
                    <Chip
                        label={!text ? "None" : text.charAt(0).toUpperCase() + text.slice(1)}
                        className={classes.button}
                        color={!text ? "default" : text === "email" ? "secondary" : "primary" }
                    />
                )
            },
            {
                key: 'submit_notes',
                title: 'Submit Notes',
                dataIndex: 'submit_notes',
                width: 200,
            },
            {
                key: 'assistance_notes',
                title: 'Assistance Notes',
                dataIndex: 'assistance_notes',
                width: 200,
            },
            {
                key: 'delete',
                title: 'Delete',
                dataIndex: 'delete',
                width: 100,
                render: (text, record) => (
                    <Row>
                        <IconButton className={classes.button} aria-label="Edit" onClick={e => { e.preventDefault(); this.handleDeleteEvent(record); }}>
                            <DeleteIcon />
                        </IconButton>
                    </Row>
                )
            },
        ];

        return (
            <Spin tip="Loading..." spinning={isLoading}>
                <FusePageCarded
                    classes={{
                        toolbar: "p-0",
                        header: "min-h-72 h-72 sm:h-136 sm:min-h-136"
                    }}
                    header={
                        <div className="flex flex-1 w-full items-center justify-between">
                            <div className="flex flex-col items-start max-w-full">
                                <div className="flex items-center max-w-full">
                                    <div className="flex flex-col min-w-0">
                                        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                                            <Title level={4} className="text-16 sm:text-20 truncate">
                                            </Title>
                                        </FuseAnimate>
                                    </div>
                                </div>
                            </div>
                            <FuseAnimate animation="transition.slideRightIn" delay={300}>
                                <Button variant="contained" size="small" href={API_KC_URL + '/project/' + user.role + '/' + user_token + '/new'} color="primary" className="whitespace-no-wrap">
                                    NEW PROJECT
                                    <SendIcon />
                                </Button>
                            </FuseAnimate>
                        </div>
                    }
                    contentToolbar={
                        <Title level={4} style={{ marginLeft: "25px", width: '100%', marginRight: "25px" }} className="text-16 sm:text-20 truncate" classes={{ root: "w-full h-64" }}>
                            Project
                            <Button color="secondary" onClick={() => { getProject(); }} size="small" style={{ float: 'right' }} variant="contained">
                                Refresh
                                <RefreshIcon />
                            </Button>
                            <Button color="secondary" onClick={this.props.downloadExcel} size="small" style={{ float: 'right', marginRight: 10 }} variant="contained">
                                {downloading && <CircularProgress size={15} style={{ marginRight: 5, color: 'white' }} />}
                                Export to Excel
                            </Button>
                        </Title>
                    }
                    content={
                        <div className="p-24">
                            <Drawer
                                anchor="right"
                                open={visible}
                                variant="temporary"
                                onClose={this.toggleDrawer(null, null, false)}
                                classes={{ paper: classes.list }}
                                onRendered={() => {
                                    var data = {
                                        user_ids: this.state.user_ids,
                                    }
                                    setFieldsValue({ ...data })
                                }}
                            >
                                <div
                                    tabIndex={0}
                                    role="button"
                                >
                                    <Title level={3} className="pt-16" style={{ textAlign: "center" }}>
                                        {type === "new" ? "Add" : "Edit"} Project
                                        </Title>
                                    <Divider />
                                    <Form layout="vertical" style={{ marginTop: "20px" }}>
                                        <Row gutter={8}>
                                            <Col span={2} />
                                            <Col span={22}>
                                                <FormItem
                                                    key="user_ids"
                                                    {...formItemLayout}
                                                    label="Designer ID"
                                                >
                                                    {getFieldDecorator('user_ids', {
                                                        initialValue: user_ids,
                                                    })(
                                                        <Select
                                                            mode="multiple"
                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ zIndex: 10000, maxHeight: 400, overflow: 'auto' }}
                                                        >
                                                            {designerItems}
                                                        </Select>,
                                                    )}
                                                </FormItem>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            left: 0,
                                            bottom: 0,
                                            width: '100%',
                                            borderTop: '1px solid #e9e9e9',
                                            padding: '10px 16px',
                                            background: '#fff',
                                            textAlign: 'right',
                                        }}
                                    >
                                        <Button onClick={this.toggleDrawer(null, null, false)} style={{ marginRight: 8 }}>
                                            Cancel
                                            </Button>
                                        <Button
                                            onClick={this.handleSubmit}
                                            variant="contained"
                                            color="primary"
                                        >
                                            {type === "new" ? "ADD" : "ASSIGN"}
                                        </Button>
                                    </div>
                                </div>
                            </Drawer>
                            <Table
                                bordered
                                rowKey="id"
                                className="mtable"
                                pagination={defaultPagination}
                                columns={columns}
                                dataSource={project}
                                indentSize={20}
                                style={{
                                    wordBreak : 'break-all'
                                }}
                                scroll={{ x: 2050, y: 500 }}
                                onChange={(pagination, filters, sorter) => {
                                    let sort = false;
                                    if (sorter.order === "ascend") {
                                        sort = true;
                                    } else {
                                        sort = false;
                                    }
                                    getProject(sort, filter);
                                    this.setState({ sort });
                                }}
                            />

                        </div>
                    }
                />
            </Spin>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getProject: Actions.getProject,
        deleteProject: Actions.deleteProject,
        updateProject: Actions.updateProject,
        showMessage: appActions.showMessage,
        downloadExcel: Actions.downloadExcel
    }, dispatch);
}

function mapStateToProps({ project, fuse, auth }) {
    return {
        downloading: project.downloadState.waiting,
        project: project.project.project,
        designer: project.project.designer,
        isLoading: fuse.loading.isLoading,
        user: auth.user
    }
}
const ProjectComponent = Form.create()(Project);

export default withReducer('project', reducer)(withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectComponent))));