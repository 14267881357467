import React, {Component} from 'react';
import * as Actions from './store/actions';
import reducer from './store/reducers';
import {withRouter} from 'react-router-dom';
import withReducer from 'app/store/withReducer';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {FusePageCarded, FuseAnimate} from '@fuse';
import {IconButton, Drawer, Divider} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Avatar from '@material-ui/core/Avatar';
import green from '@material-ui/core/colors/green';
import { withStyles } from '@material-ui/core/styles';
import * as appActions from 'app/store/actions';
import {Table, Typography, Input, TreeSelect, Upload, Select, Form, Modal, Row, Spin, Col, Icon, Tooltip, Switch, Checkbox, Button} from 'antd';
import {beautifyJSON, PH_ARRAY, canUpload} from 'app/main/config';
import Highlighter from 'react-highlight-words';
import { DoneOutline } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import CabinetDoorStyleDrawer from './CabinetDoorStyleDrawer';
import CabinetUploadBuildupDrawer from './CabinetUploadBuildupDrawer';
import CabinetDoorStyleTableModal from './CabinetDoorStyleTableModal';
import { CsvBuilder } from 'filefy';

const axios = require('axios');
const { Title } = Typography;
const {TextArea} = Input;
const { confirm } = Modal;
const FormItem = Form.Item;
const { TreeNode } = TreeSelect;
const { Dragger } = Upload;
const {Option} = Select;
const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 12 },
};

const defaultPagination = {
    pageSizeOptions: ['10', '20', '50', '70'],
    showSizeChanger: true,
    size: 'small',
    position: 'top',
}

const styles = theme => ({
    
    button: {
        marginBottom: "14px",
    },
    list: {
        width: 750,
    },
    list1: {
        width: 700,
    },

    greenAvatar: {
        margin: 10,
        color: green[500],
        backgroundColor: '#dee',
        width: 200,
        height: 200,
    },
    bigIcon: {
        width: 150,
        height: 150,
    },
});

class UnitInput extends React.Component {
    static getDerivedStateFromProps(nextProps) {
      // Should be a controlled component.
      if ('value' in nextProps) {
        return {
          ...(nextProps.value || {}),
        };
      }
      return null;
    }
  
    constructor(props) {
      super(props);
  
      const value = props.value || {};
      this.state = {
        number: value.number.toString() || '0',
        currency: value.currency || 'inch',
      };
    }
  
    handleNumberChange = e => {
      var number = (e.target.value);

      if (!('value' in this.props)) {
        this.setState({ number });
      }
      this.triggerChange({ number });
    };
  
    handleCurrencyChange = currency => {
      if (!('value' in this.props)) {
        this.setState({ currency });
      }
      this.triggerChange({ currency });
    };
  
    triggerChange = changedValue => {
      // Should provide an event to pass value to Form.
      const { onChange } = this.props;
      if (onChange) {
        onChange(Object.assign({}, this.state, changedValue));
      }
    };
  
    render() {
      const { size } = this.props;
      const { state } = this;
      return (
        <span>
          <Input
            type="text"
            size={size}
            value={state.number}
            onChange={this.handleNumberChange}
            style={{ width: '65%', marginRight: '3%' }}
          />
          <Select
            value={state.currency}
            size={size}
            style={{ width: '32%' }}
            onChange={this.handleCurrencyChange}
          >
            <Option value="inch">inch</Option>
            <Option value="cm">cm</Option>
          </Select>
        </span>
      );
    }
}


const PH_UNKNOWN = 'PH_UNKNOWN';
const PH_DOOR = 'PH_DOOR';
const PH_DRAWER = 'PH_DRAWER';
//const PH_DRAWER_DOOR = 'PH_DRAWER_DOOR';
const PH_FIXED_DRAWER_DOOR = 'PH_FIXED_DRAWER_DOOR';

class Cabinet extends Component {
   
    constructor(props) {
        super(props);
        const value = props.value || {};
        this.state = {
            buildUpVisible: false,
            zip: [],
            isFirst: false,
            width: value.width || 0,
            height: value.height || 0,
            width_unit: 'inch',
            height_unit: 'inch',
            length: value.length || 0,
            euroLength: 0,
            euroLength_unit: 'inch',
            euroWidth: 0,
            euroWidth_unit: 'inch',
            euroHeight: 0,
            euroHeight_unit: 'inch',
            euroShape_svg: [],
            length_unit: 'inch',
            sku_number   : '',
            cabinet_name : '',
            cabinet_long_name: '',
            comments     : '',
            category_id  : null,
            is_not_molding   : true,
            gltf         : [],
            thumbnail    : [],
            shape_svg    : [],
            outline  : [],
            visible      : false,
            type         : "new",
            id           : null,
            cabinet_type : null,
            is_euro_cabinet: false,
            location_type : null,
            is_corner    : 0,
            visibleModal : 0,
            leftBlindLength : value.leftBlindLength || 0,
            rightBlindLength : value.rightBlindLength || 0,
            leftBlindLength_unit : 'inch',
            rightBlindLength_unit : 'inch',
            cds_ids: [],
            cds_visible  : false,
            cds_type     : "new",
            cds_id       : null,
            cds_data     : {
                    collection_id       : null,
                    manufacturer_id     : null,
                    cabinet_door_style_name : '',
                    basic_cds           : null,
                    base_gltf           : [],
                    sku                 : '',
                    door_color_ids      : [],
                },
            
            cds_filter_collection: [],
            cds_filter_door_color: [],

            cds_tbl_visible : false,

            ph_info: []
        };
        props.getCabinet();
        props.getCabinetDoorStyle();
        props.getConstData();
    }

    checkNumber = (rule, value, callback) => {
        if (value.number > 0 || value === '.') {
            callback();
            return;
        }
        callback('Number must greater than zero!');
    };

    checkHeight = (rule, value, callback) => {
        if (!isNaN(value.number) && parseFloat(value.number) == value.number) {
            callback();
            return;
        }
        callback('Height must be number!');
    };

    exportCSV() {
        const { cabinet, cabinetDoorStyle, doorColorData } = this.props;
        let csvBuilder = new CsvBuilder("cabinet_data.csv");

        let mainColName = ["No", "Cabinet_Name", "Cabinet_SKU", "Cabinet_Type", "Corner", "Cabinet_Category_Name", "CDS_Name"];

        let extraCDSColName = ["CDS_SKU", "Manufacturer", "Door_Style"];
        let extraCDSColData = ["sku", "manufacturer_name", "door_style_name"];

        let extraDoorColorColName = ["Door_Color_SKU", "Door_Color_Name"];
        let extraDoorColorColData = ["sku", "name"];

        let extraPriceColName = ["sku_real", "regular_price", "discounted_price", "qty", "is_assembled"]

        const option = 1;
        if (option === 0) {
            csvBuilder.setColumns(mainColName.concat(["CDS Count"]).concat(extraCDSColName).concat(extraDoorColorColName));
            cabinet.map((record, index) => {
                const cdsArr = record.cabinet_door_style_ids.split(',');
                let cdsExport = "";
                let extraExport = [];
                for (let i = 0; i < cdsArr.length; i++) {
                    let cds = cabinetDoorStyle.filter(element => { return element.id === parseInt(cdsArr[i]) });
                    if (cds.length === 0 || cds[0] === undefined) continue;
                    cds = cds[0];
                    cdsExport += cds.cabinet_door_style_name;
                    if (i !== cdsArr.length - 1) cdsExport += ', ';

                    extraCDSColData.forEach((prop, j) => {
                        if (i === 0) extraExport.push(cds[prop]);
                        else extraExport[j] += cds[prop];
                        if (i !== cdsArr.length - 1) extraExport[j] += ', ';
                    })
                }
                csvBuilder.addRow([index + 1, record.cabinet_long_name, record.sku_number, record.cabinet_type, record.is_corner, record.cabinet_category_name, cdsExport, cdsArr.length].concat(extraExport))
            })
        } else {
            csvBuilder.setColumns(mainColName.concat(extraCDSColName).concat(extraDoorColorColName).concat(extraPriceColName));
            let index = 1;
            cabinet.map(record => {
                const cdsArr = record.cabinet_door_style_ids.split(',');
                for (let i = 0; i < cdsArr.length; i++) {
                    let cds = cabinetDoorStyle.filter(element => { return element.id === parseInt(cdsArr[i]) });
                    if (cds.length === 0 || cds[0] === undefined) continue;
                    cds = cds[0];
                    let extraCDSExport = [];
                    extraCDSColData.forEach(prop => { extraCDSExport.push(cds[prop]); })
                    if (cds.door_color_ids === null) { // If cds has no door_color_ids, it would be old cds.
                        // csvBuilder.addRow([index++, record.sku_number, record.cabinet_type, record.is_corner, record.cabinet_category_name, cds.cabinet_door_style_name].concat(extraCDSExport));
                    } else {          
                        const doorColorArr = cds.door_color_ids.split(',');
                        for (let j = 0; j < doorColorArr.length; j++) {
                            let doorColor = doorColorData.filter(element => { return element.id === parseInt(doorColorArr[j]) });
                            if (doorColor.length === 0 || doorColor[0] === undefined) continue;
                            doorColor = doorColor[0];
                            let extraDoorColorExport = [];
                            extraDoorColorColData.forEach(prop => { extraDoorColorExport.push(doorColor[prop]); })
                            csvBuilder.addRow([index++, record.cabinet_long_name, record.sku_number, record.cabinet_type, record.is_corner, record.cabinet_category_name, cds.cabinet_door_style_name].concat(extraCDSExport).concat(extraDoorColorExport));
                        }
                    }
                }
            })
        }
        csvBuilder.exportFile();

        this.props.showMessage({
            message: 'Successfully Exported',
            autoHideDuration: 2000,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            }, variant: 'success'
        });
    }

    multiUploadFileArray = (dataArray, record) => {
        if (dataArray === undefined) dataArray = [];
        const results = dataArray.map((obj,index) => ({
            uid     : index,
            name    : obj.split('/').pop(),
            status  : 'done',
            url     : obj,
        }));

        return results;
    }

    toggleDrawer = (type, record, open) => {
        this.props.getCabinetCategory();
        this.props.setModalData(undefined);
        if(type === "new") {
            this.props.setCabinetDoorStyleIDs([]);
            this.setState({
                // cds_ids: [],
                sku_number   : '',
                cabinet_name : '',
                cabinet_long_name: '',
                category_id  : null,
                gltf         : [],
                thumbnail    : [],
                shape_svg    : [],
                outline    : [],
                width        : 0,
                height       : 0,
                length         : 0,
                euroWidth    : 0,
                euroHeight   : 0,
                euroLength   : 0,
                comments     : '',
                visible      : false,
                type         : "new",
                id           : null,
                cabinet_type : null,
                location_type: null,
                is_corner    : 0,
                is_euro_cabinet: false,
                visibleModal : 0,
                leftBlindLength : 0,
                rightBlindLength : 0,
            })
        }
        else if(type === "edit") {
            const gltfArray = JSON.parse(record.gltf);
            this.setState({is_not_molding : Object.keys(gltfArray.gltf).length > 0});
            const gltfFileList = this.multiUploadFileArray(gltfArray.gltf, record);
            const cabinetDoorStyleIdArray = record.cabinet_door_style_ids.split(',');
            const numberIdFromStringArray = cabinetDoorStyleIdArray.map(obj => (
                parseInt(obj)
            ));
            this.props.setCabinetDoorStyleIDs(numberIdFromStringArray);
            this.setState({
                // cds_ids: numberIdFromStringArray,
                sku_number   : record.sku_number,
                cabinet_name : record.cabinet_name,
                cabinet_long_name: record.cabinet_long_name,
                category_id  : record.cabinet_category_id,
                width        : {number:record.width, currency:record.width_unit},
                height       : {number:record.height, currency:record.height_unit},
                length       : {number:record.length, currency:record.length_unit},
                leftBlindLength : {number:record.leftBlindLength, currency:record.leftBlindLength_unit},
                rightBlindLength : {number:record.rightBlindLength, currency:record.rightBlindLength_unit},
                comments         : record.comments,
                name: record.name,
                type            : type,
                id              : record.id,
                cabinet_type    : record.cabinet_type,
                location_type   : record.location_type,
                is_corner       : record.is_corner,
                is_euro_cabinet : record.is_euro_cds? true: false,
                euroHeight      : record.euro_height,
                euroWidth       : record.euro_width,
                euroLength      : record.euro_length,
                euroHeight_unit : record.euro_height_unit || 'inch',
                euroLength_unit : record.euro_length_unit || 'inch',
                euroWidth_unit  : record.euro_width_unit || 'inch',
                guid            : record.guid,
                euroShape_svg: record.euro_shape_svg ? [{
                    uid     : 1,
                    name    : record.euro_shape_svg.split('/').pop(),
                    status  : 'done',
                    url     : record.euro_shape_svg,
                }] : [],
                thumbnail: [{
                    uid     : 1,
                    name    : record.thumbnail.split('/').pop(),
                    status  : 'done',
                    url     : record.thumbnail,
                }],
                shape_svg: record.shape_svg ? [{
                    uid     : 1,
                    name    : record.shape_svg.split('/').pop(),
                    status  : 'done',
                    url     : record.shape_svg,
                }] : [],
                outline: record.outline ? [{
                    uid     : 1,
                    name    : record.outline.split('/').pop(),
                    status  : 'done',
                    url     : record.outline,
                }] : [],
                gltf: gltfFileList,
                visibleModal : 0,
            })
        }
        this.setState({
          visible: open,
          isFirst: false,
        });
    };

    toggleBuildUpDrawer = (visible) => {
        this.setState({buildUpVisible: visible});
    };

    // canBeSubmitted()
    // {
    //     const {sku_number, thumbnail, gltf, type} = this.state;
    //     return type==="new" ? (sku_number.length > 0 && thumbnail.length > 0 && gltf.length > 0) : (sku_number.length > 0);
    // }

    handleSubmitZip = () => {
        if(this.state.zip.length == 0){
            this.props.showMessage({
                message         : 'Not zip file',
                autoHideDuration: 2000,
                anchorOrigin    : {
                    vertical  : 'top',
                    horizontal: 'right'
                },variant : 'error'
            });
            return;
        }
        else{
            this.props.uploadBuildUp(this.state.zip);
            this.toggleBuildUpDrawer(false);
        }    
    }

    handleSubmit = () => {
        this.setState({isFirst: true});
        this.props.form.validateFieldsAndScroll((err, values) => {
            console.log(err);
            if (!err && this.props.cds_ids.length !== 0) {
                console.log('ok');
                const {type, id} = this.state;
                // if(this.props.modalData === undefined || this.props.modalData.length > 0) {
                //     this.props.showMessage({
                //         message         : 'Please Validate.',
                //         autoHideDuration: 2000,
                //         anchorOrigin    : {
                //             vertical  : 'top',
                //             horizontal: 'right'
                //         },variant : 'warning'
                //     })
                //     return;
                // }
                // else
                const sizeinfo = {
                    width: {
                        ...values.width,
                        number: parseFloat(values.width ? values.width.number : 0),
                    },
                    height: {
                        ...values.height,
                        number: parseFloat(values.height ? values.height.number : 0),
                    },
                    length: {
                        ...values.length,
                        number: parseFloat(values.length ? values.length.number : 0),
                    },
                    leftBlindLength: {
                        ...values.leftBlindLength,
                        number: parseFloat(values.leftBlindLength ? values.leftBlindLength.number : 0),
                    },
                    rightBlindLength: {
                        ...values.rightBlindLength,
                        number: parseFloat(values.rightBlindLength ? values.rightBlindLength.number : 0),
                    },
                }
                values = { ...values, cabinet_door_style_id: this.props.cds_ids, ...sizeinfo };
                if(type === "new") {
                    this.props.addCabinet({...values});
                }
                else if(type === "edit" && id !== null) {
                    this.props.updateCabinet({...values}, id, this.state.guid);
                }
                this.setState({visible: false, ...values});
            }
        });
    }

    handleDeleteEvent = (record) => {
        const {deleteCabinet} = this.props;
        confirm({
            title: 'Do you want to delete this Item?',
            onOk() {
                deleteCabinet(record);
            },
            onCancel() {},
        });
    }

    handleValidate = () => {
        this.setState({isFirst: true});
        this.props.form.validateFieldsAndScroll(['gltf', 'cabinet_door_style_id'], (errors, values) => {
            if (!errors && this.props.cds_ids.length !== 0) {
                const gltfFile = values.gltf && values.gltf.filter(element => element.name.endsWith(".gltf"));
                if(gltfFile.length !== 1) {
                    this.props.showMessage({
                        message         : 'Select one gltf File.',
                        autoHideDuration: 2000,
                        anchorOrigin    : {
                            vertical  : 'top',
                            horizontal: 'right'
                        },variant : 'error'
                    })
                }
                else {
                    if ( gltfFile[0].originFileObj === undefined) //read uploaded gltf file
                    {
                        axios.get(gltfFile[0].url)
                        .then(res => {
                            const gltfData =  JSON.stringify(res.data);
                            this.props.validateCabinet({id: this.props.cds_ids, gltfData });
                            this.setState({visibleModal: 1});
                        })
                        .catch(err => {
                            console.log("validation Error : failed to read gltf file.\n", err);
                        })
                    } 
                    else {
                        const reader = new FileReader();
                        reader.onload = e => {
                            const gltfData =  e.target.result;
                            this.props.validateCabinet({id: this.props.cds_ids, gltfData });
                            this.setState({visibleModal: 1});
                        };
                        reader.readAsText(gltfFile[0].originFileObj);
                    }
                }
            }
        });
    }

    handleGltfPreview = () => {
        this.props.form.validateFields(['gltf'], (errors, values) => {
            if (!errors) {
                const gltfFile = values.gltf.filter(element => element.name.endsWith(".gltf"));
                if(gltfFile.length !== 1) {
                    this.props.showMessage({
                        message         : 'Select one gltf File.',
                        autoHideDuration: 2000,
                        anchorOrigin    : {
                            vertical  : 'top',
                            horizontal: 'right'
                        },variant : 'error'
                    })
                }
                else {
                    const getPlaceholderData = (data) => {
                        var result = [];
                        var gltfData =  data.nodes;
                        gltfData.forEach((n, key) => {
                            if(n.name.startsWith('ph_'))
                                result.push(JSON.stringify(n));
                        })
                        return result;
                    }
                    if ( gltfFile[0].originFileObj === undefined) //read uploaded gltf file
                    {
                        axios.get(gltfFile[0].url)
                        .then(res => {
                            this.props.setModalData(getPlaceholderData(res.data));
                            this.setState({visibleModal: 2});
                        })
                        .catch(err => {
                            console.log("preview Error : failed to read gltf file.\n", err);
                        })
                    } else { //read uploading gltf file
                        const reader = new FileReader();
                        reader.onload = e => {
                            this.props.setModalData(getPlaceholderData(JSON.parse(e.target.result)));
                            this.setState({visibleModal: 2});
                        };
                        reader.readAsText(gltfFile[0].originFileObj);
                    }
                }
            }
        });
    }

    get_ph_type = (ph_name) => {
        if (ph_name.includes('_drawer_door_fixed')) {
            return PH_FIXED_DRAWER_DOOR;
        }
        if (ph_name.includes('interior_drawer')) {
            return PH_DRAWER;
        }
        if (!ph_name.includes('interior') && !ph_name.includes('drawer')) {
            return PH_DOOR;
        }
        return PH_UNKNOWN;
    }

    calc_ph_info = (fileList) => {
        let ph_info = {
            PH_DOOR: 0,
            PH_DRAWER: 0,
            PH_DRAWER_DOOR: 0,
            PH_FIXED_DRAWER_DOOR: 0,
        };

        const gltfFile = fileList.filter(element => element.name.endsWith(".gltf"));
        if(gltfFile.length !== 1) {
            return;
        }
        else {
            const getPlaceholderData = (data) => {
                var gltfData =  data.nodes;
                gltfData.forEach((n, key) => {
                    let name = n.name;
                    if(name.startsWith('ph_')) {
                        switch (this.get_ph_type(name)) {
                            case PH_DOOR:               ph_info.PH_DOOR++;              break;
                            case PH_DRAWER:             ph_info.PH_DRAWER++;            break;
                            case PH_FIXED_DRAWER_DOOR:  ph_info.PH_FIXED_DRAWER_DOOR++; break;
                            default:
                                break;
                        }
                    }
                })
                this.setState({ph_info});
                console.log(ph_info);
            }
            if ( gltfFile[0].originFileObj === undefined) //read uploaded gltf file
            {
                axios.get(gltfFile[0].url)
                .then(res => {
                    getPlaceholderData(res.data);
                })
                .catch(err => {
                    console.log("preview Error : failed to read gltf file.\n", err);
                    return;
                })
            } else { //read uploading gltf file
                const reader = new FileReader();
                reader.onload = e => {
                    getPlaceholderData(JSON.parse(e.target.result));
                };
                reader.readAsText(gltfFile[0].originFileObj);
            }
        }
    }

    handleAddCDS = () => {
        const data = {
            collection_id       : null,
            manufacturer_id     : null,
            cabinet_door_style_name : '',
            basic_cds           : null,
            base_gltf           : [],
            sku                 : '',
            door_color_ids      : [],
        }
        this.setState({
            cds_visible : true,
            cds_data : data, 
            cds_type : "new",
            cds_id: null,
            cds_filter_collection: [],
            cds_filter_door_color: [],
        });
    }

    handleEditCDS = (record) => {

        const doorArray = JSON.parse(record.cabinet_door);
        const drawerArray = JSON.parse(record.cabinet_drawer);
        const baseGltf = JSON.parse(record.base_gltf);

        const baseGltfList = baseGltf !== null ? this.multiUploadFileArray(baseGltf.gltf) : [];
        var door_color_ids = [];
        if(record.door_color_ids !== null) {
            door_color_ids = record.door_color_ids.split(',');
            door_color_ids = door_color_ids.map(el => {
                return parseInt(el, 10)
            })
        }
        const data = {
            collection_id       : record.collection_id,
            manufacturer_id     : record.manufacturer_id,
            cabinet_door_style_name : record.cabinet_door_style_name,
            basic_cds           : record.basic_cds,
            base_gltf           : baseGltfList,
            sku                 : record.sku,
            door_color_ids,
        }

        PH_ARRAY.forEach((el, key) => {
            data['door_' + el] = this.multiUploadFileArray(doorArray['door_' + el]);
            data['fixed_drawer_door_' + el] = this.multiUploadFileArray(drawerArray['fixed_drawer_door_' + el]);
            data['drawer_' + el] = this.multiUploadFileArray(drawerArray['drawer_' + el]);
            data['drawer_door_' + el] = this.multiUploadFileArray(drawerArray['drawer_door_' + el]);
        });
        
        const {collectionData, doorColorData} = this.props;
        const cds_filter_collection = collectionData.filter(element => {return element.manufacturer_id === record.manufacturer_id});
        const cds_filter_door_color = doorColorData.filter(element => {return element.collection_id === record.collection_id});
        this.setState({
            cds_visible : true,
            cds_data : data, 
            cds_type : "edit",
            cds_id: record.id,
            cds_guid  : record.guid,
            cds_filter_collection,
            cds_filter_door_color,
            euroHeight: record.euro_height,
            euroHeight_unit: record.euro_height_unit || 'inch',
            euroLength: record.euro_length,
            euroLength_unit: record.euro_length_unit || 'inch',
            euroWidth: record.euro_width,
            euroWidth_unit: record.euro_width_unit || 'inch',
            euroShape_svg: record.euro_shape_svg ? [{
                uid     : 1,
                name    : record.euro_shape_svg.split('/').pop(),
                status  : 'done',
                url     : record.euro_shape_svg,
            }] : [],
            is_euro_cabinet: record.is_euro_cds ? true: false,
        })
    }

    handleDeleteCDS = (record) => {
        const {deleteCabinetDoorStyle} = this.props;
        const {id} = this.state;
        confirm({
            title: 'Do you want to delete this Item?',
            onOk() {
                deleteCabinetDoorStyle(id, record)
            },
            onCancel() {},
        });
    }

    handleViewCDS = () => {
        this.setState({
            cds_tbl_visible : true,
        });
    }

    handleCDSSubmit = (data) => {
        const {cds_type, cds_id, cds_guid} = this.state;
        this.setState({cds_visible: false});
        PH_ARRAY.forEach((el, key) => {
          data['door_' + el] = data['door_' + el] || [];
          data['fixed_drawer_door_' + el] = data['fixed_drawer_door_' + el] || [];
          data['drawer_' + el] = data['drawer_' + el] || [];
          data['drawer_door_' + el] = data['drawer_door_' + el] || [];
        });
        
        if(cds_type === "new") {
            this.props.addCabinetDoorStyle(this.state.id, data, this.state.shape_svg);
        }
        if(cds_type === "edit" && cds_id !== null) {
            this.props.updateCabinetDoorStyle(data, cds_id, cds_guid, this.state.shape_svg);
        }
    }
    handleChange = (val, lab) => {
        if (lab[0].toLowerCase().includes("molding"))
            this.setState({
                is_not_molding : false,
            });
        else
            this.setState({
                is_not_molding : true,
            });
    }
    normFile = e => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    }

    moveAction = (type, record) => () => {
        const {cabinet} = this.props;
        const index = cabinet.findIndex( el => el.id === record.id);
        let swapIndex = -1; 
        if (type === "up" && index !== 0 && cabinet[index-1].cabinet_category_id === record.cabinet_category_id) {
            swapIndex = index - 1;
        }
        else if (type === "down" && index !== cabinet.length - 1 && cabinet[index+1].cabinet_category_id === record.cabinet_category_id) {
            swapIndex = index + 1;
        }
        if(swapIndex !== -1) {
            this.props.setSortOrder({id: cabinet[index].id, sort_order: cabinet[swapIndex].sort_order});
            this.props.setSortOrder({id: cabinet[swapIndex].id, sort_order: cabinet[index].sort_order});
        }
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={'search...'}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
        render: text => (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text === null ? "" : text.toString()}
          />
        ),
    });

    onInputChange = e => {
        this.setState({ searchText: e.target.value })
    }
    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
    };
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render()
    {
        const { cds_ids, classes, cabinet, cabinetCategory, cabinetDoorStyle, modalData, isLoading} = this.props;
        const { 
            zip,
            isFirst,
            cds_tbl_visible, csv_modal,
            cds_data, cds_visible, cds_type, 
            thumbnail, shape_svg, outline,
            cabinet_name, cabinet_long_name, sku_number, comments, height, cabinet_type, is_euro_cabinet, location_type, is_corner, length, width,
            category_id, is_not_molding, gltf, visible, type, visibleModal, height_unit, width_unit, length_unit,
            leftBlindLength, leftBlindLength_unit, rightBlindLength, rightBlindLength_unit} = this.state;
        const { getFieldDecorator, setFieldsValue } = this.props.form;
        const cds_tbl_data = [];
        const menuItems = [];
        let temp = null;
        cabinetDoorStyle.forEach(fElement => {
            menuItems.push(<Option key={fElement.id} value={fElement.id}>{fElement.cabinet_door_style_name}</Option>);
            if(cds_ids.some(element => { return element === fElement.id})) {
                cds_tbl_data.push(fElement);
            }
        });
        const cabinetData = [
            {id:0,name:'Base'},
            {id:1,name:'Wall'},
            {id:2,name:'Tall'},
            {id:3,name:'Vanity'},
            {id:4,name:'Accessories'},
        ];
        const typeItems = [];
        cabinetData.forEach(fElement => {
            typeItems.push(<Option key={fElement.id} value={fElement.name}>{fElement.name}</Option>);
        })
        
        const locationData = [
            {id:0,name:'Top'},
            {id:0,name:'Middle'},
            {id:0,name:'Bottom'},
        ];
        const locationtype = [];
        locationData.forEach(fElement => {
            locationtype.push(<Option key={fElement.id} value={fElement.name}>{fElement.name}</Option>);
        })

        var minWidth = 700;
        const modalContent = [];
        if(modalData !== undefined) {
            modalData.forEach((e, key) => {
                var re = /[[\]"]/g;
                const splitStr = e.split(re);
                modalContent.push(
                    (<Row key={key}>
                        <Highlighter
                            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                            searchWords={splitStr.filter(x => x.startsWith('ph_'))}
                            autoEscape
                            textToHighlight={e}
                        />
                        <br/>
                    </Row>)
                );
            })
            if((visibleModal === 1) && (modalData.length === 0)) {
                minWidth =400;
                modalContent.push(
                    (<Grid key={0} container justify="center" alignItems="center">
                        <Avatar className={classes.greenAvatar}>
                            <DoneOutline className={classes.bigIcon}/>
                        </Avatar>
                    </Grid>)
                );
            }
        }
        const loop = data => data.map((item) => {
            if (item.children && item.children.length) {
                return <TreeNode key={item.id.toString()} value={item.id} title={item.name}>{loop(item.children)}</TreeNode>;
            }
            else {
                return <TreeNode key={item.id.toString()} value={item.id} title={item.name}/>;
            }
        });
        const props = {
            name: 'file',
            multiple: true,
        };
        const uploadButton = (
            <div>
              <AddIcon />
              <div className="ant-upload-text">
                {type==="new" ? "Upload" : "Change & Upload"}
            </div>
            </div>
        );
        const columns = [
            {
                key: 'published',
                title: 'Published',
                dataIndex: 'is_published',
                width: 100,
                fixed: 'left',
                render: (text, record) =>{ 
                    return (
                        <Row>
                            <Switch
                                checkedChildren={<Icon type="check" />}
                                unCheckedChildren={<Icon type="close" />}
                                defaultChecked={text > 0}
                                onChange={(value) => {
                                    this.props.setPublishedFlag(record.id, value);
                                }}
                            />
                        </Row> 
                    );
                },
            },
            {
                key: 'active',
                title: 'Active',
                dataIndex: 'is_active',
                width: 100,
                fixed: 'left',
                render: (text, record) =>{ 
                    return (
                        <Row>
                            <Switch
                                checkedChildren={<Icon type="check" />}
                                unCheckedChildren={<Icon type="close" />}
                                defaultChecked={text > 0}
                                onChange={(value) => {
                                    this.props.setActiveFlag(record.id, value);
                                }}
                            />
                        </Row> 
                    );
                },
            },
            {
                key: 'sku_number',
                title: 'SKU Number',
                dataIndex: 'sku_number',
                width: 100,
            },
            {
                key: 'cabinet_long_name',
                title: 'Long Name',
                dataIndex: 'cabinet_long_name',
                width: 200,
                render: (text, record) => (
                    <span style={{overflowWrap: 'anywhere'}}> {text} </span>
                )
            },
            {
                key: 'cabinet_name',
                title: 'Name',
                dataIndex: 'cabinet_name',
                ...this.getColumnSearchProps('cabinet_name'),
                width: 200,
            },
            {
                key: 'cabinet_type',
                title: 'Cabinet Type',
                dataIndex: 'cabinet_type',
                ...this.getColumnSearchProps('cabinet_type'),
                width: 200,
            },
            {
                key: 'is_corner',
                title: 'Corner',
                dataIndex: 'is_corner',
                width: 100,
            },
            {
                key: 'cabinet_category_name',
                title: 'Cabinet Category',
                dataIndex: 'cabinet_category_name',
                ...this.getColumnSearchProps('cabinet_category_name'),
                width: 200,
            },
            {
                key: 'gltf_bin',
                title: 'Gltf & Bin file',
                dataIndex: 'gltf',
                width: 300,
                render: (text, record) => {
                    const data = beautifyJSON(text);
                    const result = [];
                    data.forEach((element, key) => {
                        result.push(<span key={key}>{element}<br/></span>);
                    })
                    return (
                    <Tooltip placement="topLeft" title={result}>
                        <span>{text.slice(0,40) + '...'}</span>
                    </Tooltip>
                    )
                }
            },
            {
                key: 'thumbnail',
                title: 'Thumbnail',
                dataIndex: 'thumbnail',
                colSpan: 2,
                width: 300,
                render: (text, record) => (
                    <Tooltip placement="topLeft" title={text}>
                        <span>{text.slice(0,40) + '...'}</span>
                    </Tooltip>
                )
            },
            {
                key: 'preview',
                dataIndex: 'thumbnail',
                colSpan: 0,
                width: 150,
                render: (text, record) => (
                    <Row className="list__thumbnail">
                        <img src={record.thumbnail} alt="" />
                    </Row>
                ),
            },
            {
                key: 'shape_svg',
                title: 'Shape(SVG)',
                dataIndex: 'shape_svg',
                colSpan: 2,
                width: 300,
                render: (text, record) => (
                    <Tooltip placement="topLeft" title={text}>
                        <span>{text ? text.slice(0,40) + '...' : '-'}</span>
                    </Tooltip>
                )
            },
            {
                key: 'preview_shape',
                dataIndex: 'shape_svg',
                colSpan: 0,
                width: 150,
                render: (text, record) => (
                    <Row className="list__thumbnail list__svg">
                        {text && <img src={text} alt="" />}
                    </Row>
                ),
            },
            {
                key: 'outline',
                title: 'Outline(SVG)',
                dataIndex: 'outline',
                colSpan: 2,
                width: 300,
                render: (text, record) => (
                    <Tooltip placement="topLeft" title={text}>
                        <span>{text ? text.slice(0,40) + '...' : '-'}</span>
                    </Tooltip>
                )
            },
            {
                key: 'preview_outline',
                dataIndex: 'outline',
                colSpan: 0,
                width: 150,
                render: (text, record) => (
                    <Row className="list__thumbnail list__svg">
                        {text && <img src={text} alt="" />}
                    </Row>
                ),
            },
            {
                key: 'Width',
                title: 'Width',
                dataIndex: 'width',
                width: 100,
            },
            {
                key: 'length',
                title: 'Depth',
                dataIndex: 'length',
                width: 100,
            },
            {
                key: 'height',
                title: 'Height',
                dataIndex: 'height',
                width: 100,
            },
            {
                key: 'leftBlindLength',
                title: 'Left Blind Length',
                dataIndex: 'leftBlindLength',
                width: 100,
            },
            {
                key: 'rightBlindLength',
                title: 'Right Blind Length',
                dataIndex: 'rightBlindLength',
                width: 100,
            },
            {
                key: 'comments',
                title: 'Comments',
                dataIndex: 'comments',
                width: 200,
            },
            {
                key: 'cabinet_door_style_name',
                title: 'Cabinet Door Style',
                dataIndex: 'cabinet_door_style_name',
                width: 300,
                render: (text, record) => (
                    <Select
                        mode="multiple"
                        disabled={true}
                        value={record.cabinet_door_style_ids==="" ? [] : record.cabinet_door_style_ids.split(',').map(obj => (parseInt(obj)))}
                        dropdownStyle={{ zIndex: 10000, maxHeight: 400, overflow: 'auto' }}
                        style={{ width: '100%' }}
                    >
                        {menuItems}
                    </Select>
                ),
            },
            {
                key: 'action',
                title: 'Action',
                dataIndex: 'id',
                width: 250,
                fixed: 'right',
                render: (text, record) => (
                    <Row>
                        <IconButton className={classes.button} aria-label="ArrowUp" onClick={this.moveAction("up", record)}>
                            <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="ArrowDown" onClick={this.moveAction("down", record)}>
                            <ArrowDownwardIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="Delete" onClick={() => {this.toggleDrawer("edit", record, true);}}>
                            <EditIcon />
                        </IconButton>
                        <IconButton className={classes.button} aria-label="Edit" onClick={e => {e.preventDefault(); this.handleDeleteEvent(record);}}>
                            <DeleteIcon />
                        </IconButton>
                    </Row> 
                ),
            },
        ];
        return (
            <Spin tip="Loading..." spinning={isLoading}>
                <FusePageCarded
                    classes={{
                        toolbar: "p-0",
                        header : "min-h-72 h-72 sm:h-136 sm:min-h-136"
                    }}
                    header={
                        <div className="flex flex-1 w-full items-center justify-between">
                            <div className="flex flex-col items-start max-w-full">
                                <div className="flex items-center max-w-full">
                                    <div className="flex flex-col min-w-0">
                                        <FuseAnimate animation="transition.slideLeftIn" delay={300}>
                                            <Title level={4} className="text-16 sm:text-20 truncate">
                                            </Title>
                                        </FuseAnimate>
                                    </div>
                                </div>
                            </div>
                            <FuseAnimate animation="transition.slideRightIn" delay={300}>
                                <div>
                                    <Button
                                        className="whitespace-no-wrap"
                                        variant="contained"
                                        onClick={()=> {this.toggleDrawer("new", null, true);}}
                                    >
                                        Add
                                    </Button>
                                    <span style={{padding: 5}}></span>
                                    <Button
                                        className="whitespace-no-wrap"
                                        variant="contained"
                                        onClick={ () => this.exportCSV() }
                                    >
                                        Export to CSV
                                    </Button>
                                    <span style={{padding: 5}}></span>
                                    <Button
                                        className="whitespace-no-wrap"
                                        variant="contained"
                                        //onClick={ () => { this.setState({buildUpVisible: true}); } }
                                        onClick={()=> {this.toggleBuildUpDrawer(true);}}
                                    >
                                         Upload Buildup
                                    </Button>
                                </div>
                            </FuseAnimate>
                        </div>
    
                    }
                    contentToolbar={
                        <Title level={4} style={{marginLeft: "25px"}} className="text-16 sm:text-20 truncate" classes={{root: "w-full h-64"}}>
                            {"Cabinet"}
                        </Title>
                    }
                    content={
                        <div className="p-24">
                            <Row>
                                <Modal
                                    title={visibleModal === 2 ? "Preview GLTF File" : (visibleModal ===  1 ? "Validation Result" : "")}
                                    zIndex={10000}
                                    style={{minWidth: minWidth}}
                                    visible={visibleModal > 0}
                                    onOk={e=> {this.setState({visibleModal: 0})}}
                                    onCancel={e=> {this.setState({visibleModal: 0})}}
                                >
                                    {modalContent}
                                </Modal>
                                <Drawer
                                    style={{zIndex: 500}}
                                    anchor="right"
                                    open={visible}
                                    variant="temporary"
                                    onClose={() => {
                                        if(this.state.id === null || cds_ids.length !== 0){
                                            this.toggleDrawer(null, null, false);
                                        }
                                    }}
                                    classes={{paper: classes.list}}
                                    onRendered={() => {
                                        var data = {
                                            // name      : this.state.name,
                                            gltf      : this.state.gltf,
                                            thumbnail : this.state.thumbnail,
                                            shape_svg : this.state.shape_svg,
                                            outline : this.state.outline,
                                            width     : this.state.width,
                                            height    : this.state.height,
                                            length      : this.state.length,
                                            cabinet_door_style_id: cds_ids,
                                            leftBlindLength : this.state.leftBlindLength,
                                            rightBlindLength : this.state.rightBlindLength,
                                        }
                                        setFieldsValue({...data})
                                        this.calc_ph_info(this.state.gltf);
                                    }}
                                >
                                    <div
                                        tabIndex={0}
                                        role="button"
                                    >
                                        <Title level={3} className="pt-16" style={{textAlign: "center"}}>
                                            { type === "new" ? "Add" : "Edit"} Cabinet
                                        </Title>
                                        <Divider />
                                        <Form layout="vertical" style={{marginTop: "20px"}}>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="sku_number"
                                                        {...formItemLayout}
                                                        label="SKU Number"
                                                    >
                                                        {getFieldDecorator("sku_number", {
                                                            initialValue: sku_number,
                                                            rules: [ {
                                                                required: true, message: 'Enter SKU Number.',
                                                            }],
                                                        })(
                                                            (<Input
                                                                placeholder="Enter SKU Number"
                                                            />)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="cabinet_long_name"
                                                        {...formItemLayout}
                                                        label="Cabinet Long Name"
                                                    >
                                                        {getFieldDecorator("cabinet_long_name", {
                                                            initialValue: cabinet_long_name,
                                                            rules: [ {
                                                                required: true, message: 'Enter Cabinet Long Name.',
                                                            }],
                                                        })(
                                                            (<Input
                                                                placeholder="Enter Cabinet Long Name"
                                                            />)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="cabinet_name"
                                                        {...formItemLayout}
                                                        label="Cabinet Name"
                                                    >
                                                        {getFieldDecorator("cabinet_name", {
                                                            initialValue: cabinet_name,
                                                            rules: [ {
                                                                required: true, message: 'Select Cabinet .gltf file.',
                                                            }],
                                                        })(
                                                            (<Input
                                                                placeholder="Select Cabinet .gltf file."
                                                                disabled
                                                            />)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="cabinet_type"
                                                        {...formItemLayout}
                                                        label="Cabinet Type"
                                                    >
                                                        {getFieldDecorator('cabinet_type', {
                                                            initialValue: cabinet_type,
                                                            rules: [{ required: true, message: 'Please select your cabinet type!' }],
                                                        })(
                                                            <Select
                                                                dropdownStyle={{zIndex: 10000, maxHeight: 400, overflow: 'auto' }} 
                                                                placeholder="Please select your cabinet type"
                                                            >
                                                                {typeItems}
                                                            </Select>,
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="is_corner"
                                                        {...formItemLayout}
                                                        label="Corner"
                                                    >
                                                        {getFieldDecorator('is_corner', {
                                                            valuePropName: 'checked',
                                                            initialValue: is_corner,
                                                        })(<Checkbox></Checkbox>,
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="category_id"
                                                        {...formItemLayout}
                                                        label="Category Name"
                                                    >
                                                        {getFieldDecorator("category_id", {
                                                            initialValue: category_id,
                                                            validateTrigger: 'onSelect',
                                                            rules: [{ required: true, message: 'Please select Cabinet Category!' }],
                                                        })(
                                                            (<TreeSelect
                                                                showSearch
                                                                dropdownStyle={{ zIndex: 10000, maxHeight: 400, overflow: 'auto' }}
                                                                placeholder="Please select Cabinet Category"
                                                                treeDefaultExpandAll
                                                                onChange={(val, lab) => this.handleChange(val,lab)}
                                                            >
                                                                {loop(cabinetCategory)}
                                                            </TreeSelect>)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            {!is_not_molding && <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="location_type"
                                                        {...formItemLayout}
                                                        label="Molding Location"
                                                    >
                                                        {getFieldDecorator('location_type', {
                                                            initialValue: location_type,
                                                            rules: [{ required: true, message: 'Please select location type!' }],
                                                        })(
                                                            <Select
                                                                dropdownStyle={{zIndex: 10000, maxHeight: 400, overflow: 'auto' }} 
                                                                placeholder="Please select location type"
                                                            >
                                                                {locationtype}
                                                            </Select>,
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>}
                                            {is_not_molding && <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="gltf"
                                                        {...formItemLayout}
                                                        label="Gltf & Bin file"
                                                    >
                                                        {getFieldDecorator("gltf", {
                                                            initialValue: gltf,
                                                            rules: [{ required: true, message: 'Please upload .gltf file!' }],
                                                            valuePropName: 'fileList',
                                                            getValueFromEvent: this.normFile,
                                                        })(
                                                            (   <Dragger {...props}
                                                                    showUploadList={true}
                                                                    onChange={info => {
                                                                        const fData = info.fileList.filter(e => e.name.endsWith('.gltf'));
                                                                        if(fData.length === 1) {
                                                                            setFieldsValue({cabinet_name: fData[0].name.slice(0, -5)});
                                                                        } else {
                                                                            setFieldsValue({cabinet_name: ''});
                                                                        }
                                                                        
                                                                        this.calc_ph_info(info.fileList);

                                                                        // const { status } = info.file;
                                                                        
                                                                        // if (status !== 'uploading') {
                                                                        //     console.log(info);
                                                                        //     this.setState({gltf:info.fileList});
                                                                        // }
                                                                        // if (status === 'done') {
                                                                        //     message.success(`${info.file.name} file uploaded successfully.`);
                                                                        // } else if (status === 'error') {
                                                                        //     message.error(`${info.file.name} file upload failed.`);
                                                                        // }
                                                                    }}
    
                                                                    beforeUpload={fileList => {
                                                                        return false;
                                                                    }}
                                                                >
                                                                    <p className="ant-upload-drag-icon">
                                                                    <Icon type="inbox" />
                                                                    </p>
                                                                    <p className="ant-upload-text">.gltf & .bin files to upload</p>
                                                                    <p className="ant-upload-hint">
                                                                    </p>
                                                                </Dragger>
                                                            )
                                                        )}
                                                    </FormItem>
                                                    <Col span={12}></Col>
    
                                                    <Col span={12}>
                                                        <Button variant="outlined" color="inherit" className={classes.button} onClick={this.handleGltfPreview}>
                                                            Preview
                                                        </Button>
                                                    </Col>
                                                </Col>
                                            </Row>}
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="cabinet_door_style_id"
                                                        {...formItemLayout}
                                                        label="Cabinet Door Styles"
                                                        validateStatus={isFirst && cds_ids.length === 0 ? 'error' : 'success'}
                                                        help={isFirst && cds_ids.length === 0? 'Please add Cabinet Door Style!' : '' }
                                                        required
                                                    >
                                                        {getFieldDecorator('cabinet_door_style_id', {
                                                            initialValue: cds_ids,
                                                        })(
                                                            <Select
                                                                disabled={true}
                                                                mode="multiple"
                                                                dropdownStyle={{ zIndex: 10000, maxHeight: 400, overflow: 'auto' }}
                                                                style={{ display:'none', width: '100%' }}
                                                                placeholder="Please add Cabinet Door Style"
                                                            >
                                                                {menuItems}
                                                            </Select>
                                                        )}
                                                        <div>
                                                            <Row gutter = {16}>
                                                                <Col span={12}>
                                                                    <Button variant="contained" color="inherit" onClick={this.handleAddCDS}>
                                                                        ADD CDS
                                                                    </Button>
                                                                </Col>
                                                                <Col span={12}>
                                                                    <Button variant="contained" color="inherit" onClick={this.handleViewCDS}>
                                                                        VIEW CDS
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            {is_not_molding && <Row gutter={8}>
                                                <Col span={10} />
                                                <Col span={14}>
                                                    <Button variant="contained" color="primary" className={classes.button} onClick={this.handleValidate} >
                                                        Validate
                                                    </Button>
                                                </Col>
                                            </Row>}
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="Thumbnail"
                                                        {...formItemLayout}
                                                        label="Thumbnail"
                                                    >
                                                        {getFieldDecorator("thumbnail", {
                                                            initialValue: thumbnail,
                                                            valuePropName: 'fileList',
                                                            rules: [{ required: true, message: 'Please upload Thumbnail!' }],
                                                            getValueFromEvent: this.normFile,
                                                        })(
                                                            ( <Upload
                                                                listType="picture-card"
                                                                className="upload-list-inline"
                                                                onRemove={file => {
                                                                    this.setState({
                                                                        thumbnail: [],
                                                                    });
                                                                }}
                                                                beforeUpload={(file, fileList) => {
                                                                    this.setState({
                                                                        thumbnail: fileList,
                                                                    });
                                                                    return false;
                                                                }}
                                                                >
                                                                {thumbnail.length > 0 ? null : uploadButton}
                                                            </Upload>)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="shape_svg"
                                                        {...formItemLayout}
                                                        label="Shape(SVG)"
                                                    >
                                                        {getFieldDecorator("shape_svg", {
                                                            initialValue: shape_svg,
                                                            valuePropName: 'fileList',
                                                            getValueFromEvent: this.normFile,
                                                        })(
                                                            ( <Upload
                                                                listType="picture-card"
                                                                className="upload-list-inline"
                                                                onChange={
                                                                    !is_not_molding && (info => {
                                                                        const fData = info.fileList.filter(e => e.name.endsWith('.svg'));
                                                                        if(fData.length === 1) {
                                                                            setFieldsValue({cabinet_name: fData[0].name.slice(0, -4)});
                                                                        } else {
                                                                            setFieldsValue({cabinet_name: ''})
                                                                        }
                                                                    })
                                                                }
                                                                onRemove={file => {
                                                                    this.setState({
                                                                        shape_svg: [],
                                                                    });
                                                                }}
                                                                beforeUpload={(file, fileList) => {
                                                                    this.setState({
                                                                        shape_svg: fileList,
                                                                    });
                                                                    return false;
                                                                }}
                                                                >
                                                                {shape_svg.length == 0 && uploadButton}
                                                            </Upload>)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="outline"
                                                        {...formItemLayout}
                                                        label="Outline(SVG)"
                                                    >
                                                        {getFieldDecorator("outline", {
                                                            initialValue: outline,
                                                            valuePropName: 'fileList',
                                                            getValueFromEvent: this.normFile,
                                                        })(
                                                            ( <Upload
                                                                listType="picture-card"
                                                                className="upload-list-inline"
                                                                onChange={
                                                                    !is_not_molding && (info => {
                                                                        const fData = info.fileList.filter(e => e.name.endsWith('.svg'));
                                                                        if(fData.length === 1) {
                                                                            setFieldsValue({cabinet_name: fData[0].name.slice(0, -4)});
                                                                        } else {
                                                                            setFieldsValue({cabinet_name: ''})
                                                                        }
                                                                    })
                                                                }
                                                                onRemove={file => {
                                                                    this.setState({
                                                                        outline: [],
                                                                    });
                                                                }}
                                                                beforeUpload={(file, fileList) => {
                                                                    this.setState({
                                                                        outline: fileList,
                                                                    });
                                                                    return false;
                                                                }}
                                                                >
                                                                {outline.length == 0 && uploadButton}
                                                            </Upload>)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem
                                                        key="width"
                                                        {...formItemLayout} 
                                                        label="Width"
                                                    >
                                                        {getFieldDecorator('width', {
                                                            initialValue: {number: width, currency: width_unit },
                                                            rules: [{ validator: this.checkNumber }]
                                                        })(
                                                            (  <UnitInput /> )
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                <FormItem
                                                    key="length"
                                                    {...formItemLayout} 
                                                    label="Depth"
                                                >
                                                    {getFieldDecorator('length', {
                                                        initialValue: { number: length, currency: length_unit },
                                                        rules: [{ validator: this.checkNumber }]
                                                        })(
                                                        ( <UnitInput /> )
                                                    )}
                                                </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                <FormItem
                                                    key="height"
                                                    {...formItemLayout} 
                                                    label="Height"
                                                >
                                                    {getFieldDecorator('height', {
                                                        initialValue: {number: height, currency: height_unit },
                                                        rules: [{ validator: is_not_molding ? this.checkNumber : this.checkHeight }]
                                                        })(
                                                        ( <UnitInput /> )
                                                        )}
                                                </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                <FormItem
                                                    key="leftBlindLength"
                                                    {...formItemLayout} 
                                                    label="Left Blind Length"
                                                >
                                                    {getFieldDecorator('leftBlindLength', {
                                                        initialValue: { number: leftBlindLength, currency: leftBlindLength_unit },
                                                        })(
                                                        ( <UnitInput /> )
                                                    )}
                                                </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                <FormItem
                                                    key="rightBlindLength"
                                                    {...formItemLayout} 
                                                    label="Right Blind Length"
                                                >
                                                    {getFieldDecorator('rightBlindLength', {
                                                        initialValue: {number: rightBlindLength, currency: rightBlindLength_unit },
                                                        })(
                                                        ( <UnitInput /> )
                                                        )}
                                                </FormItem>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col span={2} />
                                                <Col span={22}>
                                                    <FormItem 
                                                        key="comments"
                                                        {...formItemLayout}
                                                        label="Comments"
                                                    >
                                                        {getFieldDecorator("comments", {
                                                            initialValue: comments,
                                                        })(
                                                            (<TextArea
                                                                placeholder="Enter comments"
                                                            />)
                                                        )}
                                                    </FormItem>
                                                </Col>
                                            </Row>
                                           
                                            <div
                                                style={{
                                                    left: 0,
                                                    bottom: 0,
                                                    width: '100%',
                                                    borderTop: '1px solid #e9e9e9',
                                                    padding: '10px 16px',
                                                    background: '#fff',
                                                    textAlign: 'right',
                                                }}
                                            >
                                                <Button 
                                                    onClick={() => {
                                                        if(this.state.id === null || cds_ids.length !== 0){
                                                            this.toggleDrawer(null, null, false);
                                                        }
                                                    }} 
                                                    style={{ marginRight: 8 }}>
                                                    Cancel
                                                </Button>
                                                <Button
                                                    onClick={this.handleSubmit} 
                                                    variant="contained" 
                                                    color="primary" 
                                                    // disabled={!this.canBeSubmitted()}
                                                >
                                                    {type === "new" ? "ADD" : "UPDATE"}
                                                </Button>
                                            </div>
                                        </Form>  
                                    </div>
                                </Drawer>
                                <CabinetDoorStyleDrawer
                                    anchor = "right"
                                    open = {cds_visible}
                                    variant = "temporary"
                                    onClose = {() => {this.setState({cds_visible: false})}}
                                    drawerClasses = {{paper: classes.list1}}
                                    defaultValue = {cds_data}
                                    type = {cds_type}
                                    isEuroCabinet = { is_euro_cabinet }
                                    setIsEuroCabinet = { (value) => this.setState ({ is_euro_cabinet: value }) }
                                    propsState = { this.state }
                                    length = { this.state.length }
                                    width = { this.state.width }
                                    height = { this.state.height }
                                    euro_length = { this.state.euroLength }
                                    euro_width = { this.state.euroWidth }
                                    euro_height = { this.state.euroHeight }
                                    euro_length_unit = {this.state.euroLength_unit}
                                    euro_height_unit = {this.state.euroHeight_unit}
                                    euro_width_unit = {this.state.euroWidth_unit}
                                    leftBlindLength = { this.state.leftBlindLength }
                                    rightBlindLength = { this.state.rightBlindLength }
                                    dataSource = {{
                                        manufacturerData: this.props.manufacturerData,
                                        collectionData: this.props.collectionData,
                                        doorColorData: this.props.doorColorData,
                                        cabinetDoorStyle: cds_tbl_data,
                                        cds_filter_door_color: this.state.cds_filter_door_color,
                                        cds_filter_collection: this.state.cds_filter_collection,
                                        ph_info: this.state.ph_info,
                                        is_not_molding: this.state.is_not_molding,
                                    }}
                                    setShapeSVG = { (value) => { this.setState ({ shape_svg: value }) }}
                                    setFieldsValue = { (value) => { setFieldsValue({cabinet_name: value }) } }
                                    uploadButton = { uploadButton }
                                    checkNumber = { this.checkNumber }
                                    checkHeight = { this.checkHeight }
                                    normFile = { this.normFile }
                                    UnitInput = { <UnitInput /> }
                                    configData = {{
                                        cds_type,
                                        cds_id: this.state.cds_id,
                                    }}
                                    handleSubmit = {this.handleCDSSubmit}
                                    handleCancel = {() => {this.setState({cds_visible: false})}}
                                    // handleFilterCollection={this.handleFilterCollection}
                                    // handleFilterDoorColor={this.handleFilterDoorColor}
                                />
                                <CabinetDoorStyleTableModal
                                    anchor = "right"
                                    open = {cds_tbl_visible}
                                    variant = "temporary"
                                    handleCancel = {() => {this.setState({cds_tbl_visible: false})}}
                                    handleOk = {() => {this.setState({cds_tbl_visible: false})}}
                                    defaultValue = {cds_tbl_data}
                                    handleEdit = {(record) => {this.handleEditCDS(record)}}
                                    handleDelete = {(record) => {this.handleDeleteCDS(record)}}
                                    // handleSubmit = {this.handleCDSSubmit}
                                    // onRendered={() => {
                                    //     var data = {
                                    //         name      : this.state.name,
                                    //         gltf      : this.state.gltf,
                                    //         thumbnail : this.state.thumbnail,
                                    //         width     : this.state.width,
                                    //         height    : this.state.height,
                                    //         length      : this.state.length,
                                    //     }
                                    //     setFieldsValue({...data})
                                    // }}
                                />
                                <Table 
                                    bordered
                                    rowKey="id"
                                    className="mtable"
                                    pagination={defaultPagination}
                                    columns={columns}
                                    dataSource={cabinet}
                                    scroll={{ x: 3450, y: 500 }}
                                />
                                <CabinetUploadBuildupDrawer
                                    anchor = "right"
                                    open = {this.state.buildUpVisible}
                                    variant = "temporary"
                                    onClose = {() => this.toggleBuildUpDrawer(false)}
                                    drawerClasses = {{paper: classes.list}}
                                    handleSubmitZip={() => this.handleSubmitZip()}
                                    uploadBuildupProps={props}
                                    uploadBuildUp = {(data) => this.props.uploadBuildUp(data)}
                                />
                            </Row>
                        </div>
                    }
                />
            </Spin>
        )
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        getCabinet: Actions.getCabinet,
        getCabinetCategory: Actions.getCabinetCategory,
        getCabinetDoorStyle: Actions.getCabinetDoorStyle,
        updateCabinet: Actions.updateCabinet,
        addCabinet: Actions.addCabinet,
        deleteCabinet: Actions.deleteCabinet,
        validateCabinet: Actions.validateCabinet,
        setModalData: Actions.setModalData,
        setPublishedFlag: Actions.setPublishedFlag,
        setActiveFlag: Actions.setActiveFlag,
        
        showMessage     : appActions.showMessage,

        getConstData: Actions.getConstData,
        addCabinetDoorStyle: Actions.addCabinetDoorStyle,
        deleteCabinetDoorStyle: Actions.deleteCabinetDoorStyle,
        updateCabinetDoorStyle: Actions.updateCabinetDoorStyle,
        setCabinetDoorStyleIDs: Actions.setCabinetDoorStyleIDs,
        setSortOrder: Actions.setSortOrder,
        uploadBuildUp: Actions.uploadBuildUp,
    }, dispatch);
}

function mapStateToProps({cabinet, fuse})
{
    return {
        cabinet             : cabinet.cabinet.cabinet,
        cabinetCategory     : cabinet.cabinet.cabinetCategory,
        modalData           : cabinet.cabinet.modalData,

        isLoading           : fuse.loading.isLoading,

        cabinetDoorStyle    : cabinet.cabinetDoorStyle.cabinetDoorStyle,
        manufacturerData    : cabinet.cabinetDoorStyle.manufacturerData,
        collectionData      : cabinet.cabinetDoorStyle.collectionData,
        doorColorData       : cabinet.cabinetDoorStyle.doorColor,
        cds_ids             : cabinet.cabinetDoorStyle.cds_ids,
    }
}
const CabinetComponent = Form.create()(Cabinet);


export default withReducer('cabinet', reducer)(withStyles(styles, {withTheme: true})(withRouter(connect(mapStateToProps,mapDispatchToProps)(CabinetComponent))));